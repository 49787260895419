import {
  useManagerFormValue,
  type INode,
  type DependentOn,
  useEvaluation,
  usePathResolution,
} from '@manager'
import type { Expression } from 'expr-eval'
import { parseValue } from '@manager/utils/diffManagerForm/utils'
import { removeLastArrayAccess } from './utils'

export type ParsedDependentOn = { path: string; expression: Expression | null }

interface UseDependentOnOptions {
  defaultCondition?: string
}

export const useDependentOn = (
  node: INode,
  parentPath: string | undefined,
  options: UseDependentOnOptions = {},
) => {
  const { getRelativePathById } = usePathResolution(parentPath)
  const { parse: _parse, evaluate: _evaluate } = useEvaluation()
  const { getProperty } = useManagerFormValue()
  const { defaultCondition = '$isDefined($value)' } = options

  const parseDependentOn = (
    dependentOn: DependentOn[] | null | undefined,
  ): ParsedDependentOn[] => {
    const _dependentOn = dependentOn ?? []
    return _dependentOn
      .map(({ id, condition }) => ({
        path: getRelativePathById(id),
        expression: _parse(condition ?? defaultCondition),
      }))
      .filter((dependentOn): dependentOn is ParsedDependentOn =>
        Boolean(dependentOn.path),
      )
  }

  // Get the length of `data` array, only available for nodes of kind OBJECT
  const getObjectNodeLength = (path: string): number | undefined => {
    const objectNodePath = removeLastArrayAccess(path)
    return (getProperty(objectNodePath) as unknown[])?.length
  }

  const evaluate = (dependentOn: DependentOn[] | null | undefined) => {
    const _dependentOn = parseDependentOn(dependentOn)

    // It's always visible if it doesn't depend on anything
    if (_dependentOn.length === 0) return true

    const evaluate = ({ path, expression }: ParsedDependentOn) => {
      // If the expression is not defined, something went wrong, so we return false
      if (!expression) return false

      const value = getProperty(path)
      const length = getObjectNodeLength(path)

      return _evaluate({
        expression,
        default: false,
        values: {
          $value: value,
          $node: node,
          $length: length,
          $isDefined: (value: unknown) => !!parseValue(value),
          $hasSomeValue: (array: unknown, key: string, value: unknown) => {
            return (
              Array.isArray(array) &&
              array.some((item) => {
                return (key as string) in item && item[key as string] === value
              })
            )
          },
        },
      })
    }

    return _dependentOn.map(evaluate).every((value) => !!value)
  }

  return { evaluate }
}
